import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, Button, FormGroup, Label } from 'reactstrap'
import Table from '../Table'
// import { courseList } from 'Views/Dashboard/Views/Meta/constants/utils'
import Select from 'react-select'
import { searchByCourse } from 'Views/Dashboard/Views/Enroll-Form/api'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import { CSVLink } from 'react-csv'
import './styles.scss'

const UsersCardData = ({
  heading = '',
  tableData = [],
  tableData2 = [],
  tableHeading = [],
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  allTags,
  relatedCourses,
  setRelatedCourses,
  rowData,
  setRowData,
  handleRelatedCoursesChange,
  handleConfirmClick,
  handleStartDateChange,
  startDate,
  endDate,
  handleEndDateChange,
  handleDateFilter,
  handleAllExportToCSV,
  selectUserData,
  handleSelectUsersListChange,
  selectedLimit,
  availableCourseList
}) => {
  const getTime = (date) => {
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString();
    return `${dateString} ${timeString}`;
  }

  function isDateRangeValid(startDate, endDate) {
    // Convert date strings to Date objects
    let dateA = new Date(startDate);
    let dateB = new Date(endDate);
    let isALessThanB = dateA <= dateB;
    return isALessThanB
  }

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        const fDate = new Date(el.createdAt)
        var formatedDateString = ''
        if (el?.startDate && el?.endDate) {
          formatedDateString = `${el.startDate} to ${el.endDate} - ${el.courseDetails?.date}`
        }
        else {
          formatedDateString = `${el.courseDetails?.date}`
        }
        var o = { ...el, createdAt: getTime(fDate), 'courseDetails.date': formatedDateString,'isPaid': (el?.courseDetails?.courseId == "satsang" || el?.courseDetails?.courseId == "samattvam" || el?.courseDetails?.courseId == "yoga-by-the-bay" || el?.courseDetails?.courseId == "fullmoon-meditation"  || el?.courseDetails?.courseId == "department-of-rehabilitation-and-physiotherapy") ? 'Registered' : el.isPaid }
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }

  }, [tableData])

  const styles = {
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'red'
  }

  // useEffect(() => {
  //   if (tableData2) {
  //     var result = tableData2.map(function(el, i) {
  //       var o = Object.assign({}, el)
  //       o.sNo = i + 1 + page * 10 - 10
  //       return o
  //     })
  //     setRowData(result)
  //   }

  // }, [tableData])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{heading}</h3>
                  <div>
                    <DatePicker
                      onChange={handleStartDateChange}
                      value={startDate}
                      clearIcon={null}
                    // className={styles}
                    />
                    <DatePicker
                      onChange={handleEndDateChange}
                      value={endDate}
                      clearIcon={null}
                    // className={styles}
                    />
                    <br />
                    {!isDateRangeValid(startDate, endDate) && <span style={{ color: 'red' }}>*Select a valid date range</span>}

                    {/* <button onClick={handleDateFilter} className='date-btn'>
                      Apply Date Filter
                    </button> */}
                  </div>

                  <Col xs={2} className='course-col'>
                    <FormGroup>
                      {/* <Label htmlFor="relatedCourses">Search by Courses</Label> */}
                      <Select
                        isMulti={false}
                        // name="Related Courses"
                        value={relatedCourses}
                        options={availableCourseList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Search Courses"
                        onChange={handleRelatedCoursesChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={1} className='users-col'>
                    <FormGroup>
                      <Select
                        isMulti={false}
                        // name="Related Courses"
                        value={selectedLimit}
                        options={selectUserData}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select No. of Users"
                        onChange={handleSelectUsersListChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={2} className='users-col'>
                    <FormGroup>
                      <button className='export-btn' onClick={() => handleConfirmClick(relatedCourses)} disabled={isDateRangeValid(startDate, endDate) ? '' : 'true'}>
                        Apply Filters</button>
                    </FormGroup>
                  </Col>
                  <Col xs={2} className='users-col'>
                    <FormGroup>
                      <CSVLink
                        data={handleAllExportToCSV()}

                        filename={'selected_users.csv'}
                        className="export-csv-btn"
                      // onClick={handleExportToCSVClick}
                      >
                        <button className='export-btn'>Export to CSV</button>
                      </CSVLink>
                      {/* <Label htmlFor="relatedCourses"> Select No. of Users</Label> */}

                    </FormGroup>
                  </Col>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default UsersCardData
